import React, {FC} from 'react';

export type svgType = {
    classes: string
}

const CheckSvg: FC<svgType> = ({classes}) => {
    return (
        <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 588.67 677.63">
            <g transform="translate(-100.71 -203.07)">
                <path
                      d="m265.69 544.94c0.02 11.27 2.9 22.23 5.29 33.17 8.17 34.88 20.68 68.41 34.09 101.56 12.73 30.26 25.52 60.58 46.5 85.84 31.23-44.4-199.26 147.06-144.56 68.28 11.93-20.73 25.1-40.69 37.88-60.89 38.51-62.08 78.47-123.23 117.94-184.7 61.13-93.89 121.79-188.23 170.95-289.05 4.56-10.66 9.13-21.31 13.69-31.97l141.9-64.11c-6.82 11.82-13.64 23.65-20.46 35.48-57.62 97.4-117.91 193.2-174.39 291.28-31.32 56.62-62.01 113.61-90.06 171.94-9.87 19.45-20.6 38.36-31.07 57.47-27.23 62.99-141.39 169.08-179.22 97.63-10.72-32.02-21.55-64.09-36.06-94.65-14.74-32.38-30.38-64.33-45.42-96.57-5.01-10.73-8.52-21.88-11.98-33.19l164.98-87.52z"/>
            </g>
        </svg>
    );
};

export default CheckSvg;