import React, {FC} from 'react';
import {svgType} from "./CheckSvg";

const CartSVG: FC<svgType> = ({classes}) => {
    return (
        <svg className={classes} xmlns="http://www.w3.org/2000/svg" version="1.1"
             viewBox="0 0 256 256">
            <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
                <path
                    d="M 77.429 61.513 H 24.313 c -0.939 0 -1.752 -0.653 -1.954 -1.571 l -8.418 -38.356 c -0.13 -0.592 0.016 -1.21 0.395 -1.682 c 0.38 -0.472 0.953 -0.747 1.559 -0.747 H 88 c 0.568 0 1.11 0.242 1.489 0.665 c 0.38 0.423 0.561 0.988 0.499 1.553 l -3.515 32.032 C 85.966 58.028 82.077 61.513 77.429 61.513 z M 25.921 57.513 h 51.507 c 2.605 0 4.784 -1.953 5.068 -4.543 l 3.271 -29.813 H 18.381 L 25.921 57.513 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 54.052 61.513 c -1.104 0 -2 -0.896 -2 -2 V 21.156 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 38.356 C 56.052 60.617 55.156 61.513 54.052 61.513 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 68.785 61.513 c -0.055 0 -0.11 -0.002 -0.166 -0.007 c -1.101 -0.091 -1.92 -1.057 -1.829 -2.157 l 3.157 -38.356 c 0.091 -1.101 1.054 -1.924 2.157 -1.829 c 1.101 0.09 1.92 1.056 1.829 2.157 l -3.157 38.356 C 70.69 60.722 69.815 61.513 68.785 61.513 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 39.318 61.513 c -1.007 0 -1.873 -0.758 -1.986 -1.782 l -4.209 -38.356 c -0.12 -1.098 0.672 -2.086 1.77 -2.207 c 1.101 -0.114 2.085 0.672 2.207 1.77 l 4.209 38.357 c 0.12 1.098 -0.672 2.085 -1.77 2.206 C 39.464 61.509 39.391 61.513 39.318 61.513 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 85.896 42.334 H 20.104 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 65.792 c 1.104 0 2 0.896 2 2 S 87 42.334 85.896 42.334 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 34.219 81.663 c -4.249 0 -7.705 -3.456 -7.705 -7.705 s 3.457 -7.705 7.705 -7.705 s 7.705 3.456 7.705 7.705 S 38.467 81.663 34.219 81.663 z M 34.219 70.253 c -2.043 0 -3.705 1.662 -3.705 3.705 s 1.662 3.705 3.705 3.705 s 3.705 -1.662 3.705 -3.705 S 36.262 70.253 34.219 70.253 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 73.16 81.663 c -4.249 0 -7.705 -3.456 -7.705 -7.705 s 3.456 -7.705 7.705 -7.705 s 7.705 3.456 7.705 7.705 S 77.409 81.663 73.16 81.663 z M 73.16 70.253 c -2.043 0 -3.705 1.662 -3.705 3.705 s 1.662 3.705 3.705 3.705 s 3.705 -1.662 3.705 -3.705 S 75.203 70.253 73.16 70.253 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 29.17 73.304 h -3.171 c -4.354 0 -7.896 -3.542 -7.896 -7.896 s 3.542 -7.896 7.896 -7.896 c 1.104 0 2 0.896 2 2 s -0.896 2 -2 2 c -2.148 0 -3.896 1.747 -3.896 3.896 s 1.748 3.896 3.896 3.896 h 3.171 c 1.104 0 2 0.896 2 2 S 30.275 73.304 29.17 73.304 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 68.112 73.304 H 39.32 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 28.792 c 1.104 0 2 0.896 2 2 S 69.217 73.304 68.112 73.304 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 82.249 73.304 h -3.384 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 3.384 c 1.104 0 2 0.896 2 2 S 83.354 73.304 82.249 73.304 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
                <path
                    d="M 15.893 23.157 c -0.919 0 -1.747 -0.637 -1.952 -1.572 l -0.566 -2.582 c -0.848 -3.862 -4.333 -6.666 -8.287 -6.666 H 2 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 3.087 c 5.818 0 10.947 4.125 12.194 9.808 l 0.566 2.582 c 0.237 1.079 -0.446 2.146 -1.525 2.382 C 16.179 23.142 16.035 23.157 15.893 23.157 z"
                    transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round"/>
            </g>
        </svg>
    );
};

export default CartSVG;
